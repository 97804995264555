<script>
export default {
  mounted() {
    // if(window.logOnProfile){
    //   location.href = '/company_settings/company_information'
    // }else location.href = '/login'
    location.href = '/login'
  },
};
</script>

<template>
  <div id="app" class="min-w-[1024px] min-h-[100vh] flex"></div>
</template>
